import { useTranslation } from 'react-i18next';

import MessageIcon from 'ui-kit/icons/message-icon/message-icon';
import VideoCameraIcon from 'ui-kit/icons/video-camera';

import './category-pill.style.scss';

interface CategoryPillProps {
    title: string;
    isNew?: boolean;
    type?: 'video' | 'message' | 'both';
}

const CategoryPillComponent = ({ title, isNew, type }: CategoryPillProps) => {
    const { t } = useTranslation();
    return (
        <div className="category-pill">
            {title}

            {type && (
                <div className="category-pill-icons">
                    {['video', 'both'].includes(type) && (
                        <div className="category-pill-icons-item">
                            <VideoCameraIcon variant="cerulean" size="small" />
                        </div>
                    )}
                    {['message', 'both'].includes(type) && (
                        <div className="category-pill-icons-item">
                            <MessageIcon variant="air-force-blue" />
                        </div>
                    )}
                </div>
            )}

            {isNew && (
                <div className="category-pill-new">
                    <MessageIcon variant="white" />
                    <span>{t('components.categoryPills.isNewLabel')}</span>
                </div>
            )}
        </div>
    );
};

export default CategoryPillComponent;
