import { MessageIconProps } from './message-icon.props';

const MessageIcon = ({ variant }: MessageIconProps) => {
    const getVariantStyle = (variant: string | undefined) => {
        switch (variant) {
            case 'white': {
                return { fill: '#fff' };
            }
            case 'air-force-blue': {
                return { fill: '#5982b2' };
            }
            default: {
                return { fill: '#039fd3' };
            }
        }
    };

    const variantStyle = getVariantStyle(variant);

    return (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.69231 6.15385H11.2308M5.69231 8.92308H9.38462M5.69231 13.0769L2 14L2.92308 11.2308V2.92308C2.92308 2.67826 3.02033 2.44347 3.19344 2.27036C3.36655 2.09725 3.60134 2 3.84615 2H13.0769C13.3217 2 13.5566 2.09725 13.7296 2.27036C13.9027 2.44347 14 2.67826 14 2.92308V12.1538C14 12.3986 13.9027 12.6335 13.7296 12.8066C13.5566 12.9796 13.3217 13.0769 13.0769 13.0769H5.69231Z"
                stroke={variantStyle.fill}
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default MessageIcon;
