import { CSSProperties } from 'react';

export interface VideoCameraIcon {
    className?: string;
    style?: CSSProperties;
    variant?: 'white' | 'cerulean';
    size?: 'small' | 'default';
}

const VideoCameraIcon = ({ className, style, variant, size = 'default' }: VideoCameraIcon) => {
    const getVariantStyle = (variant: string | undefined) => {
        switch (variant) {
            case 'white': {
                return { fill: '#FFF' };
            }
            case 'cerulean': {
                return { fill: '#03A4DC' };
            }
            default: {
                return { fill: '#93A9B8' };
            }
        }
    };

    const variantStyle = getVariantStyle(variant);

    if (size === 'small') {
        return (
            <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.7056 4.75L11.1026 6V4.5C11.1026 4.23478 11.0053 3.98043 10.8322 3.79289C10.6591 3.60536 10.4243 3.5 10.1795 3.5H3.25641C3.01159 3.5 2.77681 3.60536 2.6037 3.79289C2.43059 3.98043 2.33334 4.23478 2.33334 4.5V11.5C2.33334 11.7652 2.43059 12.0196 2.6037 12.2071C2.77681 12.3946 3.01159 12.5 3.25641 12.5H10.1795C10.4243 12.5 10.6591 12.3946 10.8322 12.2071C11.0053 12.0196 11.1026 11.7652 11.1026 11.5V10L13.7056 11.25C13.7758 11.2793 13.8516 11.2897 13.9262 11.28C14.0009 11.2704 14.0724 11.2412 14.1342 11.1948C14.1961 11.1485 14.2466 11.0865 14.2813 11.0142C14.316 10.9419 14.3339 10.8615 14.3333 10.78V5.22C14.3339 5.13851 14.316 5.05811 14.2813 4.98581C14.2466 4.91351 14.1961 4.85149 14.1342 4.80516C14.0724 4.75882 14.0009 4.72958 13.9262 4.71996C13.8516 4.71035 13.7758 4.72066 13.7056 4.75Z"
                    stroke={variantStyle.fill}
                    strokeWidth={1.25}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }

    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16" fill="none">
                <path
                    d="M18.5584 3.125L14.6538 5V2.75C14.6538 2.35217 14.5079 1.97064 14.2483 1.68933C13.9886 1.40804 13.6364 1.25 13.2692 1.25H2.88461C2.51739 1.25 2.16521 1.40804 1.90554 1.68933C1.64588 1.97064 1.5 2.35217 1.5 2.75V13.25C1.5 13.6478 1.64588 14.0294 1.90554 14.3107C2.16521 14.5919 2.51739 14.75 2.88461 14.75H13.2692C13.6364 14.75 13.9886 14.5919 14.2483 14.3107C14.5079 14.0294 14.6538 13.6478 14.6538 13.25V11L18.5584 12.875C18.6637 12.919 18.7774 12.9346 18.8894 12.92C19.0014 12.9056 19.1085 12.8618 19.2013 12.7922C19.2941 12.7228 19.3698 12.6298 19.4219 12.5213C19.474 12.4128 19.5008 12.2922 19.5 12.17V3.83C19.5008 3.70776 19.474 3.58717 19.4219 3.47872C19.3698 3.37027 19.2941 3.27724 19.2013 3.20774C19.1085 3.13823 19.0014 3.09437 18.8894 3.07994C18.7774 3.06553 18.6637 3.08099 18.5584 3.125Z"
                    stroke={variantStyle.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default VideoCameraIcon;
