import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

export type HealthCareQueryProps = {
    allTaxonomyTermHealthCare: {
        nodes: {
            name: string;
            description: {
                processed: string;
            };
            relationships: {
                field_hc_icon: {
                    relationships: {
                        field_media_svg: {
                            localFile: {
                                publicURL: string;
                            };
                        };
                    };
                };
                field_hc_image: {
                    relationships: {
                        field_media_image: {
                            uri: {
                                url: string;
                            };
                            localFile: ImageDataLike;
                        };
                    };
                    field_media_image: {
                        alt: string;
                    };
                };
                field_hc_conditions: {
                    name: string;
                }[];
            };
        }[];
    };
};

export const HealthCareQuery = () => {
    return useStaticQuery<HealthCareQueryProps>(graphql`
        query {
            allTaxonomyTermHealthCare(filter: { status: { eq: true } }) {
                nodes {
                    name
                    description {
                        processed
                    }
                    relationships {
                        field_hc_icon {
                            relationships {
                                field_media_svg {
                                    localFile {
                                        publicURL
                                    }
                                }
                            }
                        }
                        field_hc_image {
                            relationships {
                                field_media_image {
                                    uri {
                                        url
                                    }
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(formats: WEBP)
                                        }
                                    }
                                }
                            }
                            field_media_image {
                                alt
                            }
                        }
                        field_hc_conditions {
                            name
                            field_is_new
                            field_type_of_visit
                        }
                    }
                }
            }
        }
    `);
};
