import { useTranslation } from 'react-i18next';

import PendingTelemedicinesCard, {
    PendingTelemedicineProps
} from './pending-telemedicines-card/pending-telemedicines-card.component';
import './pending-telemedicines.style.scss';

export interface PendingTelemedicinePayload {
    payload?: PendingTelemedicineProps[];
}

const PendingTelemedicinesComponent = ({ payload }: PendingTelemedicinePayload) => {
    const { t } = useTranslation();
    return (
        <div className="telemedicines">
            <div className="container px-0">
                <div className="pending-telemedicines">
                    <div className="pending-telemedicines__heading">
                        <h2>{t('pages.membership.pages.dashboard.labels.telemedicine')}</h2>
                        <p>{t('pages.membership.pages.dashboard.labels.telemedicineSubtitle')}</p>
                    </div>

                    {payload && (
                        <div className="pending-telemedicines__body">
                            {payload.map((pendingTelemedicineData, index) => (
                                <PendingTelemedicinesCard
                                    key={`pending-tele-cart-${index}`}
                                    {...pendingTelemedicineData}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PendingTelemedicinesComponent;
