import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

// Components & UI
import Button from 'ui-kit/button/button';

import './health-care-list.style.scss';

type HealthCareListProps = {
    children: ReactElement | ReactElement[];
    handleRequestTelemedicine: Function;
};

const HealthCareList: React.FC<HealthCareListProps> = ({ children, handleRequestTelemedicine }): ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="health-care">
            <div className="container px-0">
                <div className="health-care__head">
                    <div className="health-care__head__titles">
                        <h2 className="health-care__head__title">{t('pages.membership.healthCare.title')}</h2>
                        <p className="health-care__head__subtitle">{t('pages.membership.healthCare.subtitle')}</p>
                    </div>
                    <Button
                        className="health-care__head__btn btn btn-bold no-min-width"
                        onClick={() => handleRequestTelemedicine()}
                        label={t('pages.membership.healthCare.buttonRequestVisit')}
                        type={'button'}
                    />
                </div>

                <div className="health-care__list">{children}</div>
            </div>
        </div>
    );
};

export default HealthCareList;
