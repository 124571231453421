import { useTranslation } from 'react-i18next';

import BadgeNew from 'ui-kit/icons/badge-new/badge-new';
import ModalCloseIcon from 'ui-kit/icons/modal-close-icon/modal-close-icon';

import telemedicineAsyncImage from '../../../assets/images/telemedicine-async.svg';
import './telemedicine-async.styles.scss';

interface TelemedicineAsyncContentProps {
    onClose: () => void;
}

const TelemedicineAsyncContent = ({ onClose }: TelemedicineAsyncContentProps) => {
    const { t } = useTranslation();

    return (
        <div className="modal-telemedicine-async">
            <button type="button" onClick={onClose} className="modal-telemedicine-async__close-btn">
                <ModalCloseIcon />
            </button>
            <div className="modal-telemedicine-async__image-container">
                <img src={telemedicineAsyncImage} alt="Telemedicine async" />
            </div>
            <div className="modal-telemedicine-async__content">
                <div className="modal-telemedicine-async__badge">
                    <BadgeNew />
                </div>
                <p className="modal-telemedicine-async__content__title">
                    {t('pages.membership.pages.dashboard.asyncModal.title')}
                </p>
                <div className="modal-telemedicine-async__content__body">
                    <p>{t('pages.membership.pages.dashboard.asyncModal.contentOne')}</p>
                    <p>{t('pages.membership.pages.dashboard.asyncModal.contentTwo')}</p>
                </div>

                <div className="modal-telemedicine-async__divider" />

                <p className="modal-telemedicine-async__content__list-note">
                    {t('pages.membership.pages.dashboard.asyncModal.listNote')}&#58;
                </p>

                <ul className="modal-telemedicine-async__content__list">
                    <li>{t('pages.membership.pages.dashboard.asyncModal.listItemOne')}</li>
                    <li>{t('pages.membership.pages.dashboard.asyncModal.listItemTwo')}</li>
                    <li>{t('pages.membership.pages.dashboard.asyncModal.listItemThree')}</li>
                    <li>{t('pages.membership.pages.dashboard.asyncModal.listItemFour')}</li>
                    <li>{t('pages.membership.pages.dashboard.asyncModal.listItemFive')}</li>
                </ul>
            </div>
        </div>
    );
};

export default TelemedicineAsyncContent;
