import { BadgeNewProps } from './badge-new.types';

const BadgeNew = ({ className, style }: BadgeNewProps) => {
    return (
        <div className={className} style={style}>
            <svg width="40" height="20" viewBox="0 0 40 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="20" rx="4" fill="#54CDA0" fillOpacity="0.24" />
                <path
                    d="M10.624 12.14H10.66V6.056C10.66 5.696 10.744 5.6 11.104 5.6H11.512C11.872 5.6 11.956 5.696 11.956 6.056V13.556C11.956 13.916 11.872 14 11.512 14H10.9C10.54 14 10.408 13.928 10.216 13.604L7.204 8.78C6.844 8.192 6.568 7.688 6.376 7.196H6.34V13.556C6.34 13.916 6.256 14 5.896 14H5.488C5.128 14 5.044 13.916 5.044 13.556V6.056C5.044 5.696 5.128 5.6 5.488 5.6H6.196C6.556 5.6 6.7 5.672 6.88 5.984L9.988 10.832C10.264 11.276 10.48 11.708 10.624 12.14ZM14.404 13.556V6.056C14.404 5.696 14.488 5.6 14.848 5.6H18.94C19.3 5.6 19.396 5.696 19.396 6.056V6.356C19.396 6.716 19.3 6.8 18.94 6.8H15.7V9.128H18.244C18.604 9.128 18.7 9.212 18.7 9.572V9.872C18.7 10.232 18.604 10.328 18.244 10.328H15.7V12.8H19.06C19.42 12.8 19.516 12.884 19.516 13.244V13.556C19.516 13.916 19.42 14 19.06 14H14.848C14.488 14 14.404 13.916 14.404 13.556ZM26.34 5.696C26.664 5.696 26.784 5.78 26.856 6.092L28.452 12.344L29.916 6.008C29.988 5.684 30.084 5.6 30.408 5.6H30.96C31.284 5.6 31.356 5.684 31.26 5.996L29.28 13.604C29.196 13.916 29.088 14 28.764 14H28.2C27.876 14 27.768 13.916 27.696 13.604L26.004 7.208L24.276 13.604C24.192 13.916 24.096 14 23.76 14H23.184C22.872 14 22.764 13.916 22.68 13.604L20.748 5.996C20.676 5.684 20.736 5.6 21.06 5.6H21.612C21.936 5.6 22.032 5.684 22.104 6.008L23.568 12.344L25.176 6.092C25.248 5.78 25.368 5.696 25.68 5.696H26.34ZM33.7148 11.984H33.4508C33.0908 11.984 32.9948 11.9 32.9828 11.54L32.8388 5.864C32.8268 5.504 32.9228 5.42 33.2828 5.42H33.8828C34.2428 5.42 34.3388 5.504 34.3268 5.864L34.1708 11.54C34.1588 11.9 34.0748 11.984 33.7148 11.984ZM32.7908 13.352C32.7908 12.92 33.0548 12.548 33.5828 12.548C34.0988 12.548 34.3868 12.92 34.3868 13.352C34.3868 13.784 34.0988 14.12 33.5828 14.12C33.0548 14.12 32.7908 13.784 32.7908 13.352Z"
                    fill="#222A3F"
                />
            </svg>
        </div>
    );
};

export default BadgeNew;
