import { useTranslation } from 'react-i18next';

// Components
import QuickLink from 'components/quick-link/quick-link.component';

// Styles
import './quick-links-membership.style.scss';

interface QuickLinksProps {
    prescriptionModal: () => void;
    previousVisits?: string;
}

const QuickLinksMembership = ({ prescriptionModal, previousVisits }: QuickLinksProps) => {
    const { t } = useTranslation();

    return (
        <section className="quick-links-membership">
            <div className="quick-links-membership__main-container container px-0">
                <QuickLink
                    type="link"
                    icon="bird-fullsize-blue"
                    iconVariant="info"
                    title={t('pages.membership.dashboardQuickLinks.manageMembership.title')}
                    bodyText={t('pages.membership.dashboardQuickLinks.manageMembership.body')}
                    to="/secure/profile/membership"
                    dataGALocation="Membership Management"
                />
                {previousVisits && (
                    <QuickLink
                        type="link"
                        icon="tele-fullsize-blue"
                        iconVariant="info"
                        title={t('pages.membership.dashboardQuickLinks.previousVisits.title')}
                        bodyText={t('pages.membership.dashboardQuickLinks.previousVisits.body')}
                        to={previousVisits}
                        dataGALocation="Previous Visits"
                    />
                )}
                <QuickLink
                    type="link"
                    icon="rx-fullsize-blue"
                    iconVariant="info"
                    title={t('pages.membership.dashboardQuickLinks.medicineCabinet.title')}
                    bodyText={t('pages.membership.dashboardQuickLinks.medicineCabinet.body')}
                    to="/secure/medicine-cabinet"
                    dataGALocation="Medicine Cabinet"
                />
                <QuickLink
                    type="button"
                    icon="dr-fullsize-blue"
                    iconVariant="info"
                    title={t('pages.membership.dashboardQuickLinks.medicationsInFormulary.title')}
                    bodyText={t('pages.membership.dashboardQuickLinks.medicationsInFormulary.body')}
                    dataGALocation="Medications in Formulary"
                    onClick={prescriptionModal}
                    onKeyDown={() => {}}
                />
            </div>
        </section>
    );
};

export default QuickLinksMembership;
