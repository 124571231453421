import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Col, Container } from 'react-bootstrap';

import PageLayout from 'components/layouts/page/page.layout';

import withSessionExpiration from 'hoc/withSessionExpiration';

import { MembershipLayoutHeaderProps, MembershipLayoutProps } from './membership.props';
import './membership.style.scss';

const MembershipHeader: React.FC<MembershipLayoutHeaderProps> = ({ image, text }) => {
    return (
        <Container fluid="xl" className="membership-layout-header px-0">
            <Col xs={6} md={8} className="membership-layout-header__title">
                {text && <h1>{text}</h1>}
            </Col>
            <Col xs={6} md={4} className="membership-layout-header__image">
                {image && (
                    <GatsbyImage
                        image={getImage(image)}
                        height="184"
                        alt={''}
                        className="membership-layout-header-image"
                    />
                )}
            </Col>
        </Container>
    );
};

const MembershipLayout: React.FC<MembershipLayoutProps> = ({ children, greetingMessage }) => {
    const { t } = useTranslation();

    const imageData = useStaticQuery(graphql`
        query {
            mcBird: file(relativePath: { eq: "assets/images/medicine-cabinet-bird.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP], height: 200)
                }
            }
        }
    `);

    return (
        <PageLayout
            pageContentClassName="membership-layout-wrapper"
            metaData={{ nodeTitle: t('pages.membership.pageTitle') }}
        >
            <div className="membership-layout">
                <MembershipHeader
                    image={imageData.mcBird}
                    text={greetingMessage ?? t('pages.medicineCabinet.greetingMessageDefault')}
                />
                <div className="membership-content">{children}</div>
            </div>
        </PageLayout>
    );
};

export default withSessionExpiration(MembershipLayout);
