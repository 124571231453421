import './membership-usage-progress-bar.style.scss';

const definePercentageOfUsage = (limit: number, usage: number) => (usage / limit) * 100;

const defineStyleBasedOnUsage = (limit: number, usage: number, withColors: boolean) => {
    const percentageOfUsage = definePercentageOfUsage(limit, usage);

    if (withColors) {
        if (limit === usage) {
            return 'flamingo';
        }

        if (limit - 1 === usage) {
            return 'mandarin';
        }

        if (percentageOfUsage >= 50) {
            return 'mustard';
        }

        return 'puerto-rico';
    } else {
        return 'ebony-clay';
    }
};

export interface MemberShipUsageProgressBarDetails {
    phrase: string;
    limit: number;
    usage: number;
    withColors?: boolean;
}

const MemberShipUsageProgressBar = ({
    phrase,
    limit,
    usage,
    withColors = false
}: MemberShipUsageProgressBarDetails) => {
    return (
        <div className="membership-usage-progress-bar">
            <div className="membership-usage-progress-bar__labels">
                <p>{phrase}</p>
                <p className={defineStyleBasedOnUsage(limit, usage, withColors)}>
                    {usage}/{limit}
                </p>
            </div>
            <progress
                className={defineStyleBasedOnUsage(limit, usage, withColors)}
                max={limit}
                value={usage}
            ></progress>
        </div>
    );
};

export default MemberShipUsageProgressBar;
